import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import Bot from '../../templates/BotPlatform/pages/Bot';
const host = process.env.GATSBY_HOST;

const viber = {
  header: {
    subTitle: 'botPlatformGMBTitle',
    text: 'botPlatformGMBSubTitle',
    logo: 'ic_gbm@2x.png',
    button: {
      text: 'botPlatformViberButtonText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`
    }
  },
  list: [
    {
      key: 'points',
      title: 'botPlatformGMBPointsTitle',
      subTitle: 'botPlatformGMBPointsSubTitle',
      image: 'gmb-entry-points@2x.png',
    },
    {
      key: 'service',
      title: 'botPlatformGMBserviceTitle',
      subTitle: 'botPlatformGMBserviceSubTitle',
      image: 'gmb-personalized-service@2x.png',
    },
    {
      key: 'keyboard',
      title: 'botPlatformGMBkeyboardsTitle',
      subTitle: 'botPlatformGMBkeyboardsSubTitle',
      image: 'gmb-keyboards@2x.png',
    },
    {
      key: 'carusels',
      title: 'botPlatformGMBCarouselsTitle',
      subTitle: 'botPlatformGMBcaruselsSubTitle',
      image: 'gmb-carousel@2x.png',
    },
    {
      key: 'Communication',
      title: 'botPlatformGMBCommunicationTitle',
      subTitle: 'botPlatformGMBCommunicationSubTitle',
      image: 'gmb-communication-center@2x.png',
    },
    {
      key: 'RichCard',
      title: 'botPlatformGMBRichCardTitle',
      subTitle: 'botPlatformGMBRichCardSubTitle',
      image: 'gmb-rich-card@2x.png',
    },
  ],
  enterprise: {
    title: 'botPlatformEnterpriseTitle',
    list: [
      {
        key: 'security',
        title: 'botPlatformEnterpriseSecurityTitle',
        subTitle: 'botPlatformEnterpriseSecuritySubTitle',
        image: 'security.png',
      },
      {
        key: 'performance',
        title: 'botPlatformEnterprisePerformanceTitle',
        subTitle: 'botPlatformEnterprisePerformanceSubTitle',
        image: 'performance.png',
      },
      {
        key: 'scale',
        title: 'botPlatformEnterpriseScaleTitle',
        subTitle: 'botPlatformEnterpriseScaleSubTitle',
        image: 'scale.png',
      }
    ]
  },
  clients: {
    title: 'botPlatformViberClientsTitle',
    list: [
      {key: 'monobank', image: 'mono-logo.png'},
      {key: 'varus', image: 'varus.png'},
      {key: 'vostokbank', image: 'vostok-logo.png'},
      {key: 'dobovo', image: 'dobovo-logo.png'},
      {key: 'allo', image: 'allo-logo.png'},
      {key: 'ekta', image: 'ekta-logo.png'},
    
    ]
  },
  form: {
    type: 'GBM',
    text: 'botPlatformGMBFormTitle',
    phoneLeft: 'gmb-phone-left@2x.png',
    phoneRight: 'gmb-phone-right@2x.png',
  }
};

const ViberPage = (props) => (
  <Layout page={'bot-platform'} {...props}>
    <Bot
      content={viber}
      type={'viber'}
      data={props.data}
      {...props}
    />
  </Layout>
);

ViberPage.propTypes = {
  data: PropTypes.object
};

export default withIntl(ViberPage);
